import './bootstrap';
import '../css/app.css';

import { flare } from "@flareapp/flare-client";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
    flare.light();
}

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

import createFileList from 'create-file-list';
window.createFileList = createFileList;